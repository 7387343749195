import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import useMediaQuery from '../utils/useMediaQuery'
import {
	IconPlay,
	IconReplay,
	IconPause,
	IconSeekBack,
	IconSeekForward,
	IconContract,
	IconExpand,
} from './Icons'

const ControlWrapper = styled.div`
	position: absolute;
	pointer-events: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: calc(2% + 0.5em);
	display: flex;
	flex-direction: column;
	justify-content: ${props => (props.isSingle ? 'center' : 'space-between')};
	align-items: ${props => (props.isSingle ? 'center' : undefined)};
	opacity: ${props => (props.isFullscreen ? 1 : 0)};
	transition: opacity 0.15s linear, transform 0.3s ease-out;
	@media (min-width: ${props => props.theme.breakpoints.l}) {
		flex-direction: row-reverse;
		align-items: ${props => (props.isSingle ? 'center' : 'flex-end')};
	}
`

const VideoControls = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
`

const FullscreenControls = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	pointer-events: none;
`

const Button = styled.button`
	color: black;
	pointer-events: auto;
	background: ${props =>
		props.large
			? 'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(245,245,245,1) 90%, rgba(225,225,225,1) 100%)'
			: props.first
			? 'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(245,245,245,1) 90%, rgba(225,225,225,1) 100%)'
			: props.last
			? 'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(245,245,245,1) 90%, rgba(225,225,225,1) 100%)'
			: 'white'};
	border-radius: ${props =>
		props.first
			? '1em 0 0 1em'
			: props.last
			? '0 1em 1em 0'
			: props.single
			? '1em'
			: '2px'};
	border-width: 0;
	min-height: ${props => (props.large ? '44px' : '44px')};
	min-width: ${props => (props.large ? '64px' : '48px')};
	padding: ${props =>
		props.first
			? '1em 4.5em 1em 1.25em'
			: props.last
			? '1em 1.25em 1em 4.5em'
			: '1em'};
	margin: ${props => (!props.single ? '0 -1.5em' : '0')};
	opacity: 0.95;
	z-index: ${props => (props.large ? 10 : 0)};
	box-shadow: inset 0 0 0 1px white, 0 0 0 2px rgba(0, 0, 0, 0),
		0 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.2s linear, opacity 0.2s linear,
		transform 0.2s ease-out;
	will-change: transform, opacity;
	:hover {
		transform: ${props =>
			props.large || props.single
				? 'translateY(-1px) scale(1.03)'
				: 'translateY(0)'};
		box-shadow: ${props =>
			props.large || props.single
				? 'inset 0 0 0 1px white, 0 0 0 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1)'
				: undefined};
		opacity: 1;
	}
	:active {
		transform: ${props =>
			props.large || props.single ? 'scale(0.97)' : 'translateY(0)'};
		box-shadow: ${props =>
			props.large || props.single
				? 'inset 0 0 0 1px white, 0 0 0 2px rgba(0, 0, 0, 0), 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.1)'
				: undefined};
		color: black;
		opacity: 0.9;
	}
	:focus {
		outline: none;
	}
	:focus-visible {
		outline: 2px solid transparent;
		box-shadow: ${props =>
			props.large || props.single
				? 'inset 0 0 0 1px white, 0 0 0 2px rgba(0, 0, 0, 0), 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.1)'
				: undefined};
	}
	.icon {
		font-size: 24px;
	}
`

const Overlay = styled.div`
	position: relative;
	:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(
			to bottom,
			rgba(var(--color-bg-rgb), 0) 0%,
			rgba(var(--color-bg-rgb), 0) 33%,
			rgba(var(--color-bg-rgb), 0.4) 100%
		);
		opacity: 0;
		transition: opacity 0.3s linear;
	}
	video {
		display: block;
	}
`

const MobileWrapper = styled.div`
	position: relative;
	width: ${props => props.width};
	& > button {
		opacity: ${props => (props.isPlaying ? 0 : 1)};
		position: absolute;
		left: calc(50% - 24px);
		top: calc(50% - 22px);
	}
	& > video {
		max-width: 100%;
	}
`

const VideoWrapper = styled.div`
	position: ${props => (props.isFullscreen ? 'static' : 'relative')};
	height: ${props => (props.isFullscreen ? '100vh' : 'auto')};
	display: flex;
	justify-content: center;
	align-items: center;
	.overlay:after {
		opacity: ${props => (props.isPaused ? 1 : 0)};
	}
	.controls {
		opacity: ${props => (props.isPaused ? 1 : 0)};
		transform: ${props =>
			props.isPaused ? 'translateY(0)' : 'translateY(24px)'};
	}
	:hover,
	:focus {
		outline: 2px solid transparent;
		.overlay:after {
			opacity: 1;
		}
		.controls {
			opacity: 1;
			transform: translateY(0);
		}
	}
	/* video::-webkit-media-controls {
		display: none !important;
	} */
	width: ${props => (props.isFullscreen ? 'auto' : props.width)};
	& video {
		width: ${props => (props.isFullscreen ? 'auto' : props.width)};
		max-height: ${props => (props.isFullscreen ? '90vh' : '100%')};
		max-width: ${props => (props.isFullscreen ? '90%' : '100%')};
		margin: ${props =>
			props.isFullscreen ? 'auto auto !important' : '0 !important'};
	}
`

const StyleWrapper = styled.div`
	width: ${props => (props.isFullscreen ? 'auto' : props.width)};
	position: relative;
	.fullscreen-enabled {
		/* position: fixed; */
		position: relative;
		pointer-events: none;
		background-color: rgb(var(--color-bg-rgb));
		transform: translateZ(8000px);
		/* transform-style: preserve-3d; */
		z-index: 3000;
		.controls {
			top: 1em;
			bottom: 4em;
			opacity: 1;
			transform: translateY(0);
			@media (min-width: ${props => props.theme.breakpoints.m}) {
				top: 0;
				bottom: 0;
			}
		}
	}
`

const Caption = styled.figcaption``

const VideoPlayer = ({
	sources,
	poster,
	width,
	muted,
	doesAutoplay,
	loop,
	caption,
	...rest
}) => {
	const ref = useRef(null)
	const refMobile = useRef(null)
	const [isPlaying, setIsPlaying] = useState(doesAutoplay)
	const [hasBeenStarted, setHasBeenStarted] = useState(doesAutoplay)
	const isPlayingMobile = false
	const [hasEnded, setHasEnded] = useState(false)

	const isNotMobile = useMediaQuery(
		'(min-device-width: 927px), (min-device-height: 927px)'
	)

	const fullscreen = useFullScreenHandle()

	useEffect(() => {
		if (isNotMobile && ref && ref.current) {
			isPlaying && ref.current.paused
				? ref.current.play()
				: ref.current.pause()
		}
	}, [isNotMobile, isPlaying, ref])

	const handlePlayPause = e => {
		setIsPlaying(v => !v)
		setHasBeenStarted(true)
		setHasEnded(false)
	}

	const handlePlayPauseMobile = e => {
		if (refMobile && refMobile.current) {
			if (refMobile.current.requestFullscreen)
				refMobile.current.requestFullscreen()
			refMobile.current.play()
		}

		e.stopPropagation()
	}

	const handleJumpBack = e => {
		if (ref && ref.current) {
			ref.current.currentTime -= 5
		}
	}

	const handleJumpForward = e => {
		if (ref && ref.current) {
			ref.current.currentTime += 5
		}
	}

	const handleEnded = e => {
		setIsPlaying(false)
		setHasEnded(true)
	}

	const handleFullscreen = e => {
		fullscreen.active ? fullscreen.exit() : fullscreen.enter()
	}

	return isNotMobile ? (
		<figure>
			<StyleWrapper width={width} {...rest}>
				<FullScreen
					//enabled={isFullscreen}
					handle={fullscreen}
				>
					<VideoWrapper
						isPaused={!isPlaying}
						isFullscreen={fullscreen.active}
						//width={width}
						tabIndex={0}
					>
						<Overlay className="overlay" onClick={handlePlayPause}>
							<video
								ref={ref}
								muted={muted}
								autoPlay={doesAutoplay}
								loop={loop}
								poster={poster || undefined}
								onEnded={handleEnded}
							>
								{sources.map(_ => {
									const type = _.match(/\.([a-z0-9]{3,4})$/)
									return (
										<source
											key={type[1]}
											src={_}
											type={
												type
													? 'video/' + type[1]
													: 'video/mp4'
											}
										/>
									)
								})}
								Your browser does not support the video tag.
							</video>
						</Overlay>

						{hasBeenStarted ? (
							<ControlWrapper className="controls">
								<FullscreenControls>
									<Button single onClick={handleFullscreen}>
										{fullscreen.active ? (
											<IconContract />
										) : (
											<IconExpand />
										)}
									</Button>
								</FullscreenControls>
								<VideoControls>
									<Button first onClick={handleJumpBack}>
										<IconSeekBack />
									</Button>
									<Button large onClick={handlePlayPause}>
										{hasEnded ? (
											<IconReplay />
										) : isPlaying ? (
											<IconPause />
										) : (
											<IconPlay />
										)}
									</Button>
									<Button last onClick={handleJumpForward}>
										<IconSeekForward />
									</Button>
								</VideoControls>
							</ControlWrapper>
						) : (
							<ControlWrapper className="controls" isSingle>
								<Button single onClick={handlePlayPause}>
									<IconPlay />
								</Button>
							</ControlWrapper>
						)}
					</VideoWrapper>
				</FullScreen>
			</StyleWrapper>
			{caption && <Caption>{caption}</Caption>}
		</figure>
	) : (
		<figure>
			<MobileWrapper isPlaying={isPlayingMobile} width={width} {...rest}>
				<video
					disableRemotePlayback={true}
					ref={refMobile}
					muted={muted}
					autoPlay={false}
					loop={loop}
					poster={poster || undefined}
					//width={width}
					onClick={handlePlayPauseMobile}
					onEnded={handleEnded}
				>
					{sources.map(_ => {
						const type = _.match(/\.([a-z0-9]{3,4})$/)
						return (
							<source
								key={type[1]}
								src={_}
								type={type ? 'video/' + type[1] : 'video/mp4'}
							/>
						)
					})}
					Your browser does not support the video tag.
				</video>

				<Button single onClick={handlePlayPauseMobile}>
					{hasEnded ? (
						<IconReplay />
					) : isPlayingMobile ? (
						<IconPause />
					) : (
						<IconPlay />
					)}
				</Button>
			</MobileWrapper>
			{caption && <Caption>{caption}</Caption>}
		</figure>
	)
}
export default VideoPlayer

VideoPlayer.propTypes = {
	sources: PropTypes.array,
	poster: PropTypes.string,
	width: PropTypes.string,
	muted: PropTypes.bool,
	autoplay: PropTypes.bool,
	loop: PropTypes.bool,
	caption: PropTypes.string,
}

VideoPlayer.defaultProps = {
	sources: [''],
	poster: '',
	muted: true,
	doesAutoplay: true,
	loop: true,
	width: '100%',
	caption: '',
}
